import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'

firebase.initializeApp({
  apiKey: 'AIzaSyDQchHfYExVpqj4c5BfbzoK4A6qsB7FEyE',
  authDomain: 'bringspothome.firebaseapp.com',
  databaseURL: 'https://bringspothome.firebaseio.com',
  projectId: 'bringspothome',
  storageBucket: 'bringspothome.appspot.com',
  messagingSenderId: '454770578500',
  appId: '1:454770578500:web:79885cebe34130f5e49811',
  measurementId: 'G-KZNB832K0V'
})

export default firebase
