import { configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'

import firebase from '../firebase'
import sessionReducer, { authChanged, initialState } from '../features/session/sessionSlice'

const preloadedState = JSON.parse(window.localStorage.getItem('bringspothome') || '{}')
// most session strte is emphemeral (not in local storage)
preloadedState.session = { ...initialState, ...preloadedState.session }

const store = configureStore({
  preloadedState,
  reducer: {
    session: sessionReducer
  },
  enhancers: [Sentry.createReduxEnhancer({
    configureScopeWithState: (scope, state) => {
      scope.setContext('session', state.session)
      if (state.session.uid) {
        scope.setUser({ id: state.session.uid, email: state.session.email })
      } else {
        scope.setUser(null)
      }
    }
  })]
})

// listen for auth changes forever; never unsubscribes
firebase.auth().onAuthStateChanged(user => {
  store.dispatch(authChanged(user))
})

export default store
