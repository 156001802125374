import 'bootstrap/dist/css/bootstrap.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'

import App from './app/App'
import store from './app/store'
import * as serviceWorker from './serviceWorker'

if (process.env.NODE_ENV === 'production') { // don't use on localhost
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    release: process.env.REACT_APP_NAME + '@' + (process.env.REACT_APP_RELEASE_HASH || process.env.NODE_ENV)
  })
}

function startApp (children) {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App>{children}</App>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  )

  serviceWorker.register()
}

export default startApp
