import './App.sass'

import Spinner from 'react-bootstrap/Spinner'
import { useSelector } from 'react-redux'
import {
  BrowserRouter as Router,
  Route, Switch
} from 'react-router-dom'

import {
  selectBlockingError, selectIsChangingUser, selectIsLoading, selectIsSaving,
  selectSaveError
} from '../features/session/sessionSlice'

function App (props) {
  const isChangingUser = useSelector(selectIsChangingUser)
  const blockingError = useSelector(selectBlockingError)

  if (isChangingUser) {
    return <SplashScreen />
  } else if (blockingError) {
    return <BlockingError error={blockingError} />
  }
  return (
    <>
      <Router>
        <Switch>
          <Route path='/'>
            <Home>{props.children}</Home>
          </Route>
        </Switch>
      </Router>
      <LoadSaveIndicator />
    </>
  )
}

function SplashScreen () {
  return (
    <div className='splash-screen'>
      <Title />
      <Spinner animation='border' size='lg' />
    </div>
  )
}

function Title () {
  return (
    <h1 className='logo-and-name'>
      <img src='img/logo-50x50.png' alt='Bring Spot Home Logo' />
      <span className='name'>Bring Spot Home</span>
    </h1>
  )
}

function BlockingError (props) {
  return (
    <div className='blocking-error'>
      <h1>Bring Spot Home</h1>
      <div>
        Sorry, we weren't able to load your data. Please try again.
      </div>
      <div className='error'>{props.error || 'unknown error'}</div>
    </div>
  )
}

function Home (props) {
  return (
    <>
      <Title />
      <div className='about'>
        Our mission is to make puppy adoptions easy. We help
        responsible breeders like you by eliminating the time-consuming
        process of juggling adoption preferences so you can spend more time
        doing what you love — raising wonderful puppies.
      </div>
      {props.children}
    </>
  )
}

function LoadSaveIndicator () {
  const isLoading = useSelector(selectIsLoading)
  const isSaving = useSelector(selectIsSaving)
  const saveError = useSelector(selectSaveError)
  let body = null
  if (isLoading || isSaving) {
    const title = isLoading ? 'Loading...' : (isSaving ? 'Saving...' : null)
    body = <Spinner animation='border' size='sm' title={title} />
  } else if (saveError) {
    body = (
      <span className='save-error' title={`Save Failed: ${saveError}`}>
        ⚠
      </span>
    )
  }
  return <span className='load-save-indicator'>{body}</span>
}

export default App
